<template>
  <div>
    <div class="databody">
      <div style="text-align:right">
        <el-input @input="searchKeyword" suffix-icon="el-icon-search" placeholder="模糊搜索" v-model="keyword" size="small"
          style="width:50%"></el-input>
      </div>
      <div
        style="display:flex; padding:10px ; font-size: 14px; vertical-align: middle; line-height: 14px; justify-content: center;">
        <span
          style="display:inline-block;width:14px; height:14px;background: linear-gradient(to bottom , #5a7fff , #a6baff);"></span>
        实训耗材
        <span
          style="display:inline-block;margin-left: 20px; width:14px; height:14px;background: linear-gradient(to bottom , #fab93f , #fad9a1);"></span>
        低值耐用品

      </div>


      <div>
        <div class="datarow" v-for="(item, idx) in DataList" :key="idx" v-if="Max > 0">
          <div class="dataname">{{  item.cname  }}</div>
          <div class="dataprocess">
            <div style="border:2px solid #f6f7ff; margin-top: 5px; padding: 2px; border-radius: 10px;">
              <div class="processin_0" :style="{ width: (item.count_num / Max * 100) + '%' }">

              </div>
            </div>
          </div>
          <div class="datanumber">{{  item.count_num  }}</div>
        </div>
      </div>


    </div>
  </div>
</template>


<script>

export default {
  props: ['datalist'],
  components: {

  },

  data() {
    return {
      keyword: "",
      Max: 0,
      DataList:[]
    }
  },
  mounted() {
    if (this.datalist && this.datalist.length > 0) {
      this.DataList = this.datalist
      this.checkMax()
    }
  },
  methods: {
    searchKeyword() {
      this.$http.post("/api/mini_reserve_statistics", { cname: this.keyword }).then(res => {

        
        this.DataList   = res.data.data

      })
    },
    checkMax() {
      let max = 0;
      for (let item of this.DataList) {
        if (max < item.count_num) {
          max = item.count_num
        }
      }
      if (max == 0) {
        this.Max = 1
      } else {
        this.Max = max * 1.2
      }



    }

  }
}

</script>

<style>
</style>
