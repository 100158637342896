<template>
  <div class="databody">
    <div id="chart" style="height:50vh"></div>
  </div>
</template>


<script>

export default {
  props: ['datalist', 'total'],
  components: {

  },

  data() {
    return {
      DataList: []
    }
  },
  mounted() {

    this.$http.post("/api/mini_rk_chart_statistics", {}).then(res => {
      for (let item of res.data.data) {
        item.month = item.month.split('-')[1] + '月'
      }
      this.DataList = res.data.data
      this.initChart()


    })




  },
  methods: {
    initChart() {
      let cols = []
      for (let item of this.DataList) {
        let arr = [item.month]
        arr.push(item.rk_num)//实训
        arr.push(0)//低值
        cols.push(arr)
      }
      let option = {
       
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          }
        },
        

        xAxis: { type: 'category', data: this.DataList.map(e => { return e.month }) },
        yAxis: {},
        legend: {
          data: ['实训耗材', '低值耐用品'],
          itemGap: 5
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            data: this.DataList.map(e => {
              return {
                value: e.rk_num,
                itemStyle: {
                  color: '#7997ff'
                }
              }
            }),
            type: 'bar',
            name: '实训耗材',
          },
          {
            data: this.DataList.map(e => {
              return {
                value: 0,//有的话放这里
                itemStyle: {
                  color: '#fac87d'
                }
              }
            }),
            type: 'bar',
            name: '低值耐用品',
          }
        ]
      };
      let myChart = this.$echarts.init(document.getElementById('chart'))
      myChart.setOption(option)
    }

  }
}

</script>

<style>
.databody {
  width: 92%;
  padding: 2%;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 0 10px 5px rgba(63, 106, 255, 0.1);
  background: #fff
}
</style>
