<template>
  <div class="h5page">

    <div class="h5inp">
      <div style="position:relative;background-image: url(/img/datacenter/data_body.png); background-size: 100% auto;">
        <div class="h5inp" style="z-index:0">
          <img :src="'/img/datacenter/data_banner.jpg'" class="img100" />
        </div>
        <div style="position:relative; z-index: 1; padding-bottom: 100px;">
          <datatag></datatag>
          <sykc v-if="activeTab == '剩余库存' && DataInfo" :datainfo="DataInfo" :total="total"></sykc>
          <rktj v-if="activeTab == '入库统计'&& DataInfo" :datainfo="DataInfo" :total="total"></rktj>
          <cktj v-if="activeTab == '出库统计'&& DataInfo" :datainfo="DataInfo" :total="total"></cktj>
          <yujing v-if="activeTab == '预警统计'&& DataInfo" :datainfo="DataInfo" :total="total"></yujing>
        </div>
      </div>


    </div>



    <bmenu></bmenu>
  </div>
</template>


<script>

import datatag from './com/datatag.vue'
import sykc from './com/sykc0.vue'
import rktj from './com/rktj0.vue'
import yujing from './com/yujing0.vue'
import cktj from './com/cktj0.vue'
import bmenu from './com/menu.vue'
export default {
  components: {
    datatag,
    sykc,
    rktj,
    cktj,
    bmenu,
    yujing
  },

  data() {
    return {
      activeTab: "",
      DataInfo: null,
      total: {
        total1: 0,
        total2: 0,
        total3: 0
      },
    }
  },
  mounted() {

  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab
      this.getData('')
    },
    getData(keyword,search) {
      this.DataInfo=null
      if (this.activeTab == "剩余库存") {
        this.$http.post("/api/mini_reserve_statistics", { cname: keyword }).then(res => {

          let total = {
            total1: res.data.sum_num,
            total2: 0,
            total3: ''
          }
          this.total = total
          this.DataInfo = res.data.data

        })
      }else if (this.activeTab == "入库统计") {
        this.$http.post("/api/mini_rk_from_statistics", { cname: keyword }).then(res => {

          let total = {
            total1: res.data.sum_num,
            total2: 0,
            total3: ''
          }
          this.total = total
          this.DataInfo = res.data.list

        })
      }else if (this.activeTab == "出库统计") {
        this.$http.post("/api/mini_ck_from_statistics", { cname: keyword }).then(res => {

          let total = {
            total1: res.data.sum_num,
            total2: 0,
            total3: ''
          }
          this.total = total
          this.DataInfo = res.data.list

        })
      }else if (this.activeTab == "预警统计") {
        this.$http.post("/api/mini_warning_statistics").then(res => {

          let total = {
            total1: res.data.sum_num,
            total2: 0,
            total3: ''
          }
          this.total = total
          this.DataInfo = res.data.data

        })
      }
    }

  }
}

</script>

<style>
.h5page {
  width: 100%;
  height: 100%;
  position: relative;
}

.h5inp {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: auto;
}

.img100 {
  width: 100%;
}


.databody {
  width: 92%;
  padding: 2%;
  min-height:70vh;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 0 10px 5px rgba(63, 106, 255, 0.1);
  background:#fff
}

.datarow {
  display: flex;
  font-size: 12px;
  color: #999999;
  height: 30px;
  line-height: 30px;

}

.dataname {
  width: 30%;
  height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dataprocess {
  width: 55%;
}

.datanumber {
  width: 15%;
  text-align: center;
}

.processin_0 {
  transition: width 2s linear;
  height: 12px;
  background: linear-gradient(to right, #a6baff, #5a7fff);
  border-radius: 6px;

}

.processin_1 {
  transition: width 2s linear;
  height: 12px;
  background: linear-gradient(to right, #fad9a1, #fab93f);
  border-radius: 6px;

}

.dataheader {
  width: 92%;
  margin: 0 auto;
  padding: 2%;
  padding-left: 2%;
  background: #fff;
  border-radius: 10px;
  margin-top: 15px;
  display: flex;
}

.hditem {
  margin: 10px;
  flex-grow: 1;
  padding-top: 14px;
  border-radius: 8px;
}

.itmimg {
  width: 60%;
  margin: 0 auto;
  padding-right: 5%;
}

.itmtitle {
  text-align: center;
  font-size: 14px;
}

.itmnum {
  text-align: center;
  font-size: 18px;
  color: #fa970a;
  padding-bottom: 10px;
}
</style>
