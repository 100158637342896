<template>
  <div>

    <div class="dataheader" v-if="Total">

      <div class="hditem" style="background:#e3e9ff;box-shadow: 0 0 1px 1px rgba(63, 106, 255,0.2);">
        <div class="itmimg">
          <img :src="'/img/datacenter/data_icon1.png'" class="img100" />
        </div>
        <div class="itmtitle">实训耗材</div>
        <div class="itmnum">{{  Total.total1  }}</div>
      </div>
      <div class="hditem" style="background:#fff4eb;box-shadow: 0 0 1px 1px rgba(250, 172, 55,0.2);">
        <div class="itmimg">
          <img :src="'/img/datacenter/data_icon2.png'" class="img100" />
        </div>
        <div class="itmtitle">低值耐用品</div>
        <div class="itmnum">{{  Total.total2  }}</div>

      </div>
      <div class="hditem" @click="exportXls" style="background:#ebf6ff;box-shadow: 0 0 1px 1px rgba(37, 131, 255,0.2);">
        <div class="itmimg">
          <img :src="'/img/datacenter/data_icon3.png'" class="img100" />
        </div>
        <div class="itmtitle">导出</div>
        <div class="itmnum" style="color:#cdd7e0;font-size: 16px; ">EXPORT</div>


      </div>


    </div>
    <div style="text-align:center;padding:10px">
      <el-button size="small" icon="el-icon-pie-chart" :type="activeTab == 'tubiao' ? 'primary' : ''"
        @click="activeTab = 'tubiao'">图表</el-button>
      <el-button size="small" icon="el-icon-data-analysis" :type="activeTab == 'tongji' ? 'primary' : ''"
        @click="activeTab = 'tongji'">详情</el-button>
    </div>

    <rktj1 v-if="activeTab == 'tubiao' && DataInfo" :datalist="DataInfo" :total="total">

    </rktj1>

    <rktj2 v-if="activeTab == 'tongji' && DataInfo" :datalist="DataInfo" :total="total">
    </rktj2>
  </div>
</template>


<script>

import rktj1 from './rktj1.vue'
import rktj2 from './rktj2.vue'
export default {
  props: ['datainfo', 'total'],
  components: {
    rktj1,
    rktj2
  },

  data() {
    return {
      activeTab: "tubiao",
      DataInfo: null,
      Total: null,
      taped:false
    }
  },
  mounted() {
    this.changeTab()
    this.DataInfo = this.datainfo
    this.Total = this.total
  },
  methods: {
    changeTab() {
      if (this.activeTab == 'tubiao') {
        
      } else {
        this.$http.post("/api/mini_rk_from_statistics", {}).then(res => {

          let total = {
            total1: res.data.sum_num,
            total2: 0,
            total3: ''
          }
          this.Total = total
          this.DataInfo = res.data.list

        })
      }

    },
    exportXls(){
      if(this.taped){
        return
      }else{
        this.taped = true
      }
      this.$http.post("/api/mini_rk_from__export").then(res=>{
        this.taped = false
        wx.miniProgram.navigateTo({url:"/pages/document/document?f="+encodeURIComponent(res.data.url)})
      })
    }
  }
}

</script>

<style scoped>
.dataheader {
  width: 92%;
  margin: 0 auto;
  padding: 2%;
  padding-left: 2%;
  background: #fff;
  border-radius: 10px;
  margin-top: 15px;
  display: flex;
}

.hditem {
  margin: 10px;
  flex-grow: 1;
  padding-top: 14px;
  border-radius: 8px;
}

.itmimg {
  width: 60%;
  margin: 0 auto;
  padding-right: 5%;
}

.itmtitle {
  text-align: center;
  font-size: 14px;
}

.itmnum {
  text-align: center;
  font-size: 18px;
  color: #fa970a;
  padding-bottom: 10px;
}
</style>
