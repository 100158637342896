<template>
  <div>

    <div class="dataheader">

      <div class="hditem" style="background:#e3e9ff;box-shadow: 0 0 1px 1px rgba(63, 106, 255,0.2);">
        <div class="itmimg">
          <img :src="'/img/datacenter/data_icon1.png'" class="img100" />
        </div>
        <div class="itmtitle">实训耗材</div>
        <div class="itmnum">{{total.total1}}</div>
      </div>
      <div class="hditem" style="background:#fff4eb;box-shadow: 0 0 1px 1px rgba(250, 172, 55,0.2);">
        <div class="itmimg">
          <img :src="'/img/datacenter/data_icon2.png'" class="img100" />
        </div>
        <div class="itmtitle">低值耐用品</div>
        <div class="itmnum">{{total.total2}}</div>

      </div>
      <div class="hditem" @click="exportXls" style="background:#ebf6ff;box-shadow: 0 0 1px 1px rgba(37, 131, 255,0.2);">
        <div class="itmimg">
          <img :src="'/img/datacenter/data_icon3.png'" class="img100" />
        </div>
        <div class="itmtitle">导出1</div>
        <div class="itmnum" style="color:#cdd7e0;font-size: 16px; ">EXPORT</div>


      </div>


    </div>
    <div style="text-align:center;padding:10px">
      <el-button size="small" icon="el-icon-pie-chart" :type="activeTab == 'tubiao' ? 'primary' : ''" @click="activeTab = 'tubiao'">图表</el-button>
      <el-button size="small" icon="el-icon-data-analysis"  :type="activeTab == 'tongji' ? 'primary' : ''" @click="activeTab = 'tongji'">统计</el-button>
    </div>

    <sykc1 v-if="activeTab == 'tubiao'&&DataInfo" :datalist="DataInfo" :total="total">
    
    </sykc1>

    <sykc2 v-if="activeTab == 'tongji'&&DataInfo" :datalist="DataInfo"  :total="total">
    </sykc2>
  </div>
</template>


<script>

import sykc1 from './sykc1.vue'
import sykc2 from './sykc2.vue'
export default {
  props:['datainfo','total'],
  components: {
    sykc1,
    sykc2
  },

  data() {
    return {
      activeTab: "tubiao",
      DataInfo:null,
      taped:false
    }
  },
  mounted() {
    this.DataInfo = this.datainfo

  },
  methods: {
    exportXls(){
      if(this.taped){
        return
      }else{
        this.taped = true
      }
      this.$http.post("/api/mini_reserve_export").then(res=>{
        this.taped = false
        wx.miniProgram.navigateTo({url:"/pages/document/document?f="+encodeURIComponent(res.data.url)})
      })
    }
  }
}

</script>

<style scoped>
.dataheader {
  width: 92%;
  margin: 0 auto;
  padding: 2%;
  padding-left: 2%;
  background: #fff;
  border-radius: 10px;
  margin-top: 15px;
  display: flex;
}

.hditem {
  margin: 10px;
  flex-grow: 1;
  padding-top: 14px;
  border-radius: 8px;
}

.itmimg {
  width: 60%;
  margin: 0 auto;
  padding-right: 5%;
}

.itmtitle {
  text-align: center;
  font-size: 14px;
}

.itmnum {
  text-align: center;
  font-size: 18px;
  color: #fa970a;
  padding-bottom: 10px;
}
</style>
