<template>
    <div style="width:100%;height:60px;overflow:hidden;">
        <div :class="['tabitem', activeTab == '剩余库存' ? 'tabitemckd' : '']" @click="checkTab('剩余库存')">
            <div class="tabname">剩余库存</div>
            <div class="ckdline"></div>
        </div>
        <div :class="['tabitem', activeTab == '入库统计' ? 'tabitemckd' : '']" @click="checkTab('入库统计')">
            <div class="tabname">入库统计</div>
            <div class="ckdline"></div>

        </div>
        <div :class="['tabitem', activeTab == '出库统计' ? 'tabitemckd' : '']" @click="checkTab('出库统计')">

            <div class="tabname">出库统计</div>
            <div class="ckdline"></div>

        </div>
        <div :class="['tabitem', activeTab == '预警统计' ? 'tabitemckd' : '']" @click="checkTab('预警统计')">
            <div class="tabname">预警统计</div>
            <div class="ckdline"></div>

        </div>
    </div>
</template>


<script>

export default {
    props:[],
    components: {

    },

    data() {
        return {
            activeTab: "剩余库存"
        }
    },
    mounted() {
        this.$$parent(this, 'changeTab', this.activeTab)

    },
    methods: {
        checkTab(e) {
            this.activeTab = e
            this.$$parent(this, 'changeTab', e)
        }

    }
}

</script>

<style scoped>
.tabitem {
    display: inline-block;
    width: 25%;
    text-align: center;
    padding-top: 20px;
    color: #fff;
}

.tabitemckd {
    color: #fff;
    font-weight: bolder;
}

.ckdline {
    width: 30%;
    height: 6px;
    background-color: orange;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 10px;
    opacity: 0;
}
.tabitemckd .ckdline{ opacity: 1;}
.tabitemckd .tabname{ transform: scale(1.1,1.1);}
</style>
