<template>
  <div>

     <div :class="['menunav',isIphoneX?'ipx':'']">
      <div class="menubox withshadow">

      </div>
      <div class="menubox" style="z-index: 3;">
        <div class="navitem" @click="setTab('index')" >
          <div class="ckdcircle" v-if="tabname=='index'"></div>
          <div class="circlemask" v-if="tabname=='index'"></div>
          <img :src="staticImgs['home'+(tabname=='index'?'1':'0')]" />
          <div :class="['navtxt', tabname=='index'?'navckd':'']">首页</div>
        </div>
        <div class="navitem" @click="setTab('msg')" >
          <div class="ckdcircle" v-if="tabname=='msg'"></div>
          <div class="circlemask" v-if="tabname=='msg'"></div>
          <img :src="staticImgs['msg'+(tabname=='msg'?'1':'0')]" />
          <div :class="['navtxt', tabname=='msg'?'navckd':'']">消息</div>
        </div>
        <div class="navitem" @click="setTab('data')" >
          <div class="ckdcircle" v-if="tabname=='data'"></div>
          <div class="circlemask" v-if="tabname=='data'"></div>
          <img :src="staticImgs['data'+(tabname=='data'?'1':'0')]" />
          <div :class="['navtxt', tabname=='data'?'navckd':'']">数据中心</div>
        </div>

        <div class="navitem" @click="setTab('me')" >
          <div class="ckdcircle" v-if="tabname=='me'"></div>
          <div class="circlemask" v-if="tabname=='me'"></div>
          <img :src="staticImgs['me'+(tabname=='me'?'1':'0')]" />
          <div :class="['navtxt', tabname=='me'?'navckd':'']">我的</div>
        </div>
         <!--
        <div class="navitem" @click="setTab" data-tab="msg">
          <div class="ckdcircle" wx:if="{{tabname=='msg'}}"></div>
          <div class="circlemask" wx:if="{{tabname=='msg'}}"></div>
          <img src="{{staticImgs['msg'+(tabname=='msg'?'1':'0')]}}" />
          <div class="navtxt {{tabname=='msg'?'navckd':''}}">消息</div>
          <div class="reddot" wx:if="{{msgcount>0}}">{{ msgcount }}</div>
        </div>

        <div class="navitem" @click="setTab" data-tab="data">
          <div class="ckdcircle" wx:if="{{tabname=='data'}}"></div>
          <div class="circlemask" wx:if="{{tabname=='data'}}"></div>
          <img src="{{staticImgs['data'+(tabname=='data'?'1':'0')]}}" />
          <div class="navtxt {{tabname=='data'?'navckd':''}}">数据中心</div>
        </div>
        <div class="navitem" @click="setTab" data-tab="me">
          <div class="ckdcircle" wx:if="{{tabname=='me'}}"></div>
          <div class="circlemask" wx:if="{{tabname=='me'}}"></div>
          <img src="{{staticImgs['me'+(tabname=='me'?'1':'0')]}}" />
          <div class="navtxt {{tabname=='me'?'navckd':''}}">我的</div>
        </div> -->
      </div>
    </div>
    
  </div>
</template>


<script>

export default {
  components: {

  },

  data() {
    return {
      isIphoneX:false,
      tabname: "data",
      staticImgs: {}
    }
  },
  mounted() {
      if(window.localStorage.getItem("ipx")){
        this.isIphoneX = true
      }
      this.getConfig()

  },
  methods: {
    getConfig() {
      this.$http.post("/api/sys_dictionary_list", {}).then(res => {
        let config = {};
        if (res.data && res.data.length > 0) {
          res.data.map(a => {
            if (a.remark.indexOf('app图片外观配置') > -1 && a.key_values) {
              
              let json_Arr = JSON.parse(a.key_values)
              for (let item of json_Arr) {
                config[item.field] = item.value
              }
              this.staticImgs = config
              console.log(this.config)
            }
          })
        }
      })
    },
    setTab(idx) {
      if(idx!=this.tabname){
        wx.miniProgram.switchTab({url:"/pages/"+idx+"/"+idx})
      }
    }
  }
}

</script>

<style scoped>
.ckdcircle {
	position: absolute;
	width: 35px;
	height: 35px;
	background-color: #fff;
	border-radius: 50%;
	box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
	left: 50%;
	transform: translate3d(-50%,-8px,0);
}
.circlemask{ 
	position: absolute;
	width: 38px;
	height: 38px;
	background-color: #fff;
	left: 50%;
	transform: translate3d(-50%,0px,0);
}

.reddot {
	width: 24px;
	height: 24px;
	text-align: center;
	line-height: 24px;
	font-size: 8px;
	color: #fff;
	background: orangered;
	position: absolute;
	top: 0;
	right: 20px;
	border-radius: 50%;
	border: 1px solid #f1f1f1;
	transform: scale(0.9,0.9);
}

.menunav {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 50px;

	border-radius: 1px;
	background: #FFFFFF;
	z-index: 999;
	font-size:12px;
	transform: translate3d(0, 0, 0);
	opacity: 1;
}

.ipx {
	border-bottom: 20px solid #fff;
}
.fitipx{
	height: 20px;
	background-color: #fff;position: fixed;bottom: 0;width: 100%;
}
.navitem {
	width: 25%;
	height: 100%;
	float: left;
	text-align: center;
	position: relative;
}

.navitem img {
	width: 33px;
	height: 33px;
	vertical-align: top;
	transform: scale(0.8, 0.8);
}

.navtxt {
	line-height: 10px;
	font-size: 12px;
}

.navckd {
	color: #1578FE;
	font-weight: bold;
	position: relative;z-index: 100;
}

.menubox {
	height: 100%;
	z-index: 1;
	position: absolute;
	width: 100%;

	background: #FFFFFF;
	color: #999999;

}

.withshadow {
	box-shadow: 0 -1px 0px 1px rgba(0, 0, 0, 0.05);
}

.mask {
	background: rgba(255, 255, 255, 0.95);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 1000;

}


.menuitem {
	width: 100px;
	height: 125px;
	font-size: 0;
	margin: 0;
	display: inline-block;
	transform-origin: 50% 50%;
	opacity: 0;
}

.menuicon {
	width: 60px;
	height: 60px;
	overflow: hidden;
	margin: 0 auto;
}

.menuicon image {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.menuname {
	text-align: center;
	font-size: 14px;
	line-height: 20px;
	padding-top: 20px;
	word-break: break-all;
	color: #333333;
}

.hidden {
	animation-name: hidden;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-duration: 0.3s;
	opacity: 1;
}


</style>
