import { render, staticRenderFns } from "./rktj2.vue?vue&type=template&id=3a61e353&scoped=true"
import script from "./rktj2.vue?vue&type=script&lang=js"
export * from "./rktj2.vue?vue&type=script&lang=js"
import style0 from "./rktj2.vue?vue&type=style&index=0&id=3a61e353&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a61e353",
  null
  
)

export default component.exports