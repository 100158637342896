<template>
  <div class="databody">
    <div id="chart" style="height:70vh"></div>
  </div>
</template>


<script>

export default {
  props:['datalist','total'],
  components: {

  },

  data() {
    return {

    }
  },
  mounted() {
    this.initChart()

  },
  methods: {
    initChart() {
      let option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        color:['#5a7fff','#fab93f'],
        series: [
          {
            name: '库存数量',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: isNaN(this.total.total1)?0:this.total.total1, name: '实训耗材' },
              { value: isNaN(this.total.total2)?0:this.total.total2, name: '低值耐用品' },
             
            ]
          }
        ]
      };
      let myChart = this.$echarts.init(document.getElementById('chart'))
			myChart.setOption(option)
    }

  }
}

</script>

<style>
  .databody {
  width: 92%;
  padding: 2%;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 0 10px 5px rgba(63, 106, 255, 0.1);
  background:#fff
}
</style>
