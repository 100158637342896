<template>
  <div>

    <div class="dataheader">
      <div style="width:100%">

        <div id="chart" style="height:50vw"></div>

        <div>
          <span style="font-weight:bolder">预警总数：</span>
          <span style="font-size:24px;color:#f75473">{{  total.total1 + total.total2  }}</span>

          <span style="font-size:12px;color:#333333;margin-left:20px">实训耗材：{{  total.total1  }}</span>
          <span style="font-size:12px;color:#333333;margin-left:20px">低值耐用品{{  total.total2  }}</span>

        </div>

      </div>


    </div>

    <div class="databody" style="margin-top:20px">
      <div style="text-align:center; padding-top:40px;padding-bottom:20px">当前预警条目（库存/预警值）</div>
      <div style="display:flex">
        <div style="width:47%">
          <div style="text-align:right;overflow:hidden;height:24px;line-height:24px;" v-for="(item, idx) in datainfo"
            :key="idx">
            <div class="hctitle">{{ item.title }}<span class="storenum" :style="{color:item.store_num>=item.yujing_num?'':'orangered'}">{{item.store_num}}/{{item.yujing_num}}</span></div>
            
            <div class="hcitem" :style="{ width: (item.store_num/Max*50) + '%' }"></div>
          </div>
        </div>
        <div style="width:6%; position:relative;z-index:2">
          <div
            style="height:100%;width:18px;padding:1px;border-radius:10px; background:linear-gradient(to bottom , #4870fe , #f9ac38); box-shadow:0 0 20px 5px #fff">
            <div
              style="height:calc(100% - 6px);width:12px; border:3px solid #fff; background:linear-gradient(to bottom , #4870fe , #f9ac38);;border-radius:10px">

            </div>
          </div>
        </div>
        <div style="width:47%"></div>
      </div>
    </div>


  </div>
</template>


<script>


export default {
  props: ['datainfo', 'total'],
  components: {

  },

  data() {
    return {
      activeTab: "tubiao",
      DataInfo: null,
      Max: 0
    }
  },
  mounted() {
    this.DataInfo = this.datainfo
    this.initChart()
    this.checkMax()
  },
  methods: {
    checkMax() {
      let max = 0;
      for (let item of this.datainfo) {
        if (max < item.store_num) {
          max = item.store_num
        }
      }
      if (max == 0) {
        this.Max = 1
      } else {
        this.Max = max * 1.2
      }



    },
    initChart() {
      let option = {

        tooltip: {
          trigger: 'item'
        },
        color: ['#5a7fff', '#fab93f'],

        series: [
          {
            name: '预警数量',
            type: 'pie',
            radius: '50%',
            data: [
              { value: this.total.total1, name: '实训耗材' },
              { value: this.total.total2, name: '低值耐用品' },

            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      let myChart = this.$echarts.init(document.getElementById('chart'))
      myChart.setOption(option)
    }

  }
}

</script>

<style scoped>
.hcitem {
  background: #a6baff;
  height: 10px;
  display: inline-block;
  vertical-align:middle;
  transform:translateY(-7px)
}

.hctitle {
  width: 50%;
  overflow: hidden;
  height: 100%;
  display: inline-block;
  text-align: center;
  font-size:12px

}
.storenum{ font-size: 12px;}
</style>
