<template>
  <div>
    <div class="databody">
      <!-- <div style="text-align:right">
        <el-input @input="searchKeyword" suffix-icon="el-icon-search" placeholder="模糊搜索" v-model="keyword" size="small"
          style="width:50%"></el-input>
      </div> -->
      <div
        style="display:flex; padding:10px ; font-size: 14px; vertical-align: middle; line-height: 14px; justify-content: center;">
        <span
          style="display:inline-block;width:14px; height:14px;background: linear-gradient(to bottom , #5a7fff , #a6baff);"></span>
        实训耗材
        <span
          style="display:inline-block;margin-left: 20px; width:14px; height:14px;background: linear-gradient(to bottom , #fab93f , #fad9a1);"></span>
        低值耐用品

      </div>


      <div>
        <table class="dtable">
          <thead style="background:#cce5ff">
            <tr>
              <td>序号</td>
              <td>耗材</td>
              <td>经办人</td>
              <td>数量</td>
              <td>详情</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in datalist" :key="idx">
              <td>{{  idx + 1  }}</td>
              <td>{{  item.title  }}</td>
              <td>{{  item.create_username  }}</td>
              <td>{{  item.rkNum  }}</td>
              <td><span style="color:#3b90ff" @click="viewInfo(item)">查看</span></td>

            </tr>
          </tbody>
        </table>


      </div>


    </div>


    <el-dialog :visible.sync="dialogVisible" width="90%" :modal-append-to-body="false">
      <div slot="header"></div>
      <div>
        <table class="dtable" v-if="EditItem" style="border-bottom: 1px solid #a7d2ff;">

          <tbody>
            <tr>
              <td style="background:#cce5ff;border-left: 1px solid #a7d2ff;width:30%">耗材名称</td>
              <td style="border-right: 1px solid #a7d2ff;">{{ EditItem.cl_name }}</td>
            </tr>
            <tr>
              <td style="background:#cce5ff;border-left: 1px solid #a7d2ff;width:30%">出库数量</td>
              <td style="border-right: 1px solid #a7d2ff;">{{ EditItem.slNum }}</td>
            </tr>
            <tr>
              <td style="background:#cce5ff;border-left: 1px solid #a7d2ff;width:30%">经办人</td>
              <td style="border-right: 1px solid #a7d2ff;">{{ EditItem.sl_username }}</td>
            </tr>
            <tr>
              <td style="background:#cce5ff;border-left: 1px solid #a7d2ff;width:30%">申领原因</td>
              <td style="border-right: 1px solid #a7d2ff;">{{ EditItem.sl_remark }}</td>
            </tr>

            <tr>
              <td style="background:#cce5ff;border-left: 1px solid #a7d2ff;width:30%">所属仓库</td>
              <td style="border-right: 1px solid #a7d2ff;">{{ EditItem.storename }}</td>
            </tr>
            <tr>
              <td style="background:#cce5ff;border-left: 1px solid #a7d2ff;width:30%">所属分类</td>
              <td style="border-right: 1px solid #a7d2ff;">{{ EditItem.typename1 }}</td>
            </tr>
            <tr>
              <td style="background:#cce5ff;border-left: 1px solid #a7d2ff;width:30%">品牌</td>
              <td style="border-right: 1px solid #a7d2ff;">{{ EditItem.pinpai }}</td>
            </tr>
            <tr>
              <td style="background:#cce5ff;border-left: 1px solid #a7d2ff;width:30%">型号</td>
              <td style="border-right: 1px solid #a7d2ff;">{{ EditItem.xinghao }}</td>
            </tr>


            <tr>
              <td style="background:#cce5ff;border-left: 1px solid #a7d2ff;width:30%">出库人</td>
              <td style="border-right: 1px solid #a7d2ff;">{{ EditItem.ck_username }}</td>
            </tr>
            <tr>
              <td style="background:#cce5ff;border-left: 1px solid #a7d2ff;width:30%">出库时间</td>
              <td style="border-right: 1px solid #a7d2ff;">{{ EditItem.ck_date }}</td>
            </tr>
          </tbody>

        </table>
      </div>
    </el-dialog>


  </div>
</template>


<script>

export default {
  props: ['datalist'],
  components: {

  },

  data() {
    return {
      dialogVisible: false,
      keyword: "",
      Max: 0,
      EditItem: null
    }
  },
  mounted() {
    if (this.datalist && this.datalist.length > 0) {
      this.checkMax()
      
    }
  },
  methods: {
    searchKeyword() {
      this.$$parent(this, 'getData', this.keyword)
    },
    checkMax() {
      let max = 0;
      for (let item of this.datalist) {
        if (max < item.count_num) {
          max = item.count_num
        }
      }
      if (max == 0) {
        this.Max = 1
      } else {
        this.Max = max * 1.2
      }
    },
    viewInfo(item) {
      this.EditItem =item
      this.dialogVisible = true
    }

  }
}

</script>

<style scoped>
.dtable,
.dtable tr th,
.dtable tr td {
  border-top: 1px solid #a7d2ff;
  -webkit-print-color-adjust: exact;
  -moz-print-color-adjust: exact;
  color-adjust: exact;
  font-size: 14px;
}

.dtable tbody tr:nth-chlid(even) {
  background-color: #000
}

.dtable {
  width: 100%;
  min-height: 25px;
  line-height: 25px;
  text-align: center;
  border-collapse: collapse;
  padding: 2px;
}
</style>
